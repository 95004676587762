import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Row, RowCard, RowCardPeople } from './styles';
import { ImageUrl } from "../../utils/constants";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Waiting from '../Waiting';
import LazyImage from '../common/LazyImage/LazyImage';
import CommonButton from '../common/CommonButton/CommonButton';
const ParticipantLocationPopup = React.lazy(() => import('../NewEmployeePopupV2/participantsLocation'));

class PeopleListView extends Component {
  constructor() {
    super();
    this.state = {
      userId: null,
      initiativeId: null,
      initiativeName: null,
      employeeList: [],
      selectedParticipant:[],
      showNote: false
    };
  }

  componentDidMount() {
    const arra = []
    const { employeeList } = this.props;
    employeeList && employeeList.length && employeeList.map((obj) => {
      obj.select = false;
      arra.push(obj)
    })
    this.setState({ employeeList: arra })
    localStorage.setItem("selectedEmployeeList", JSON.stringify([]))
  }

  onEnterMouse = (uid, id, name) => {
    this.setState({
      userId: uid,
      initiativeId: id,
      initiativeName: name
    })
  };

  onLeaveMouse = () => {
    this.setState({
      userId: null,
      initiativeId: null
    })
  };

  activeTick = (index) => {
    let prev = this.state.employeeList;
    let newData = this.props.employeeList;
    let totalData = prev.concat(newData)
    let objIndex = totalData.findIndex(((obj) => obj.uid == index));
    const prevSelect = totalData[objIndex].select;
    totalData[objIndex].select = !prevSelect;
    let selectedArray = totalData.filter((obj) => obj.select === true)
    let unique = selectedArray.filter((item, index) => selectedArray.indexOf(item) === index);
    this.setState({
      employeeList: totalData,
      selectedParticipant: unique
    });
    localStorage.setItem("selectedEmployeeList", JSON.stringify(selectedArray))

  }

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }
  goToPersonalProfile = (employeeId) => {
    const { history } = this.props;
    history.push(`/company/people/${employeeId}/personal-profile`)
  }
  render() {
    const { employeeList, showEmployeeDetails, selectedEmployee, sendFriendRequestPost, searchAmigo, t, show, plp } = this.props;
    const { userId, initiativeId, initiativeName } = this.state;
    let uidArray = [];
    this.state.selectedParticipant.map((obj) => {
      uidArray.push(obj.uid)
    })
    return (
      <div>
        <div className={!(_.isNull(employeeList)) && !(_.isUndefined(employeeList)) && employeeList.length > 5 ?
          "scrollGrid" : 'notScroll'} >
          {
            !(_.isNull(employeeList)) && !(_.isUndefined(employeeList)) && employeeList.length > 0
              ?
              employeeList.map((employee, index) => (
                searchAmigo?<RowCard key={index} onClick={() => showEmployeeDetails(employee.uid)} active={selectedEmployee === employee.uid} deleted={employee.is_user_deleted&&!searchAmigo ? 1 : 0}>
               
                  <div className="wrapper">
                    {show && <div style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                      <div style={{ width: '24px', height: '24px', border: '1px solid #9C9C9C', borderRadius: '21px', display: 'flex' }} onClick={() => this.activeTick(employee.uid)} >
                        {employee.select && <img src={ImageUrl + '/ChallengeDetailsScreen/updatedCheckmark.svg'}/>}
                      </div> 
                    </div>}
                    <LazyImage div={"profile"} onError={this.fallBack} src={`${ImageUrl}/${employee.profile_image}`}/>
                    <div className="user">
                      <div className="name">{t(employee.fname)} {t(employee.lname)}
                        {employee.is_user_deleted&&!searchAmigo?<div className="remove-user-text">{t("User will be removed end of Month")}</div>:employee.is_champion ? <div className="wellness-champions-text">{t("Wellness Champion")}</div>:null}
                      </div>
                      <div className="department">{t(employee.department)}</div>
                    </div>
                  </div>
                  <div className="wellness-card">
                    {employee.interests && employee.interests.map((Wellness, index) => (
                      index < 5 &&
                      <div key={index} onMouseEnter={() => this.onEnterMouse(employee.uid, Wellness.interest_id, Wellness.interest_name)} onMouseLeave={() => this.onLeaveMouse()}>
                        <img src={`${ImageUrl}/${Wellness.interest_icon}`} />
                        {userId === employee.uid && initiativeId === Wellness.interest_id &&
                          <div className="hover-card">
                            <div> {t(initiativeName)}</div>
                          </div>}
                      </div>))}
                  </div>
                  {searchAmigo && employee.mutual_friends && employee.mutual_friends.length > 0 ?
                    <div className="profile-image-card">
                      {employee.mutual_friends && employee.mutual_friends.length > 0 && employee.mutual_friends.map((mutual, index) =>
                        index < 2 &&
                        <img onError={this.fallBack} src={`${ImageUrl}/${mutual.profile_image}`} key={index} />
                      )}
                      {employee.mutual_friends && employee.mutual_friends.length > 2 &&
                        <div className="count">{employee.mutual_friends.length - 2}+</div>}
                    </div> :
                    employee.total_global_points && !searchAmigo ?
                      <div className='no-data'> 
                        {`${employee.total_global_points} Pts`}
                      </div>
                      :
                      <div className="no-data">NA</div>
                  }

                  {!searchAmigo ?
                    // <RequestButton
                    //   onClick={() => this.goToPersonalProfile(employee.uid)}
                    //   background='#69C2FF'>{t("View Profile")}
                    // </RequestButton>
                    <CommonButton
                      onClick={() => this.goToPersonalProfile(employee.uid)}
                      btnType={"square"}
                      styles={{background:'#69C2FF',cursor:'pointer'}}
                      title={t("View Profile")}
                    />
                    :
                    employee.friend === 0 ?
                      <CommonButton
                        onClick={() => sendFriendRequestPost(employee.uid)}
                        btnType={"square"}
                        styles={{cursor:'pointer', margin:"auto 15px auto 0px", background:"#FD7175"}}
                        title={t("Add Buddy")}
                      />
                      : employee.friend === 1 ?
                        <CommonButton
                          btnType={"square"}
                          styles={{cursor:'pointer', margin:"auto 15px auto 0px", background:"rgb(159, 201, 137)"}}
                          title={t("Buddies")}
                        />
                        : employee.friend === 2 ?
                          <CommonButton
                            styles={{background:"rgb(246, 180, 121)",margin:"auto 15px auto 0px"}}
                            btnType={"square"}
                            title={t("Pending")}
                          />
                          : <CommonButton btnType={"square"}  styles={{margin:"auto 15px auto 0px",background:"#fff"}}  />}
                </RowCard>:
                  <RowCardPeople key={index} onClick={() => showEmployeeDetails(employee.uid)} active={selectedEmployee === employee.uid} deleted={employee.is_user_deleted&&!searchAmigo ? 1 : 0}>
                    <p>hello</p>
                    <div className="wrapper">
                      {show && <div style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div style={{ width: '24px', height: '24px', border: '1px solid #9C9C9C', borderRadius: '21px', display: 'flex' }} onClick={() => this.activeTick(employee.uid)} >
                          {employee.select && <img src={ImageUrl + '/ChallengeDetailsScreen/updatedCheckmark.svg'}/>}
                        </div> 
                      </div>}
                      <LazyImage div={"profile"} onError={this.fallBack} src={`${ImageUrl}/${employee.profile_image}`}/>
                      <div className="user">
                        <div className="name">{t(employee.fname)} {t(employee.lname)}
                          {employee.is_user_deleted&&!searchAmigo?<div className="remove-user-text">{t("User will be removed end of Month")}</div>:employee.is_champion ? <div className="wellness-champions-text">{t("Wellness Champion")}</div>:null}
                        </div>
                        <div className="department">{t(employee.department)}</div>
                      </div>
                    </div>
                    <div className="no-data">{employee && employee.location?employee.location:"NA"}</div>
                    <div className="wellness-card">
                      {employee.interests && employee.interests.map((Wellness, index) => (
                        index < 5 &&
                                    <div key={index} onMouseEnter={() => this.onEnterMouse(employee.uid, Wellness.interest_id, Wellness.interest_name)} onMouseLeave={() => this.onLeaveMouse()}>
                                      <img src={`${ImageUrl}/${Wellness.interest_icon}`} />
                                      {userId === employee.uid && initiativeId === Wellness.interest_id &&
                                        <div className="hover-card">
                                          <div> {t(initiativeName)}</div>
                                        </div>}
                                    </div>))}
                    </div>
                    {/* <RequestButton
                      onClick={() => this.goToPersonalProfile(employee.uid)}
                      background='#69C2FF'>{t("View Profile")}
                    </RequestButton> */}
                    <CommonButton
                      btnType={"square"}
                      onClick={employee.is_user_deleted?"":() => this.goToPersonalProfile(employee.uid)}
                      styles={{margin:"auto 15px auto 0px", background:"#69C2FF" ,cursor:employee.is_user_deleted?"not-allowed":"pointer"}}
                      title={t("View Profile")}
                    />
                  </RowCardPeople>
              ))
              :
              <Row disableCursor={1} align={1}>
                {t("No Employee")}
              </Row>
          }
          {<Suspense fallback={<Waiting/>}>
            {!searchAmigo &&
            <ParticipantLocationPopup companyId={plp.companyId} fetchEditLocationApi={plp.fetchEditLocationApi}
              show={plp.show} departmentData={plp.departmentData} handleShowNote={plp.handleShowNote} data={plp.data} showModalPopUp={plp.showModalPopUp} selectedParticipant={this.state.selectedParticipant} companyAuthDetails={plp.companyAuthDetails}/>}
          </Suspense>
          }
        </div>
      </div>
    )
  }
}

PeopleListView.propTypes = {
  employeeList: PropTypes.array.isRequired,
  showEmployeeDetails: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.any,
  changeOrder: PropTypes.func,
  searchAmigo: PropTypes.bool,
  history: PropTypes.object,
  challenge: PropTypes.object,
  sendFriendRequestPost: PropTypes.func,
  t: PropTypes.func,
  plp:PropTypes.object,
  show: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  sentRequest: state.social.sentRequest
});

export default connect(mapStateToProps, null)(withTranslation()(PeopleListView));
